import getTenant from '../../getTenant';

const idm = `/org/user_management`;

export function getRegisterSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/register/`;
}

export function getLoginSlug(): string {
    return `${backendUrl}/auth/login?successSlug=news%2F&errorSlug=de%2Flogin-error&apiId=${backendApiId}`;
}

export function getAdfsUserManagementSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/adfs/`;
}

export function getApplicationsSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/applications/`;
}

export function getNewsletterSlug(): string {
    return `${getTenant().toLowerCase()}${idm}/newsletter/`;
}

export function getUserProfilePageUrl(): string {
    return `${getTenant().toLowerCase()}/org/user_management/user_profile`;
}

export function getWelcomePageSlug(): string {
    return `${getTenant().toLowerCase()}/`;
}

export function getAdfsChangePasswordUrl(): string {
    return `${backendUrlWithPathPrefix}/adfs/change_password`;
}

export function getAdfsChangeMfaUrl(): string {
    return `${backendUrlWithPathPrefix}/adfs/change_mfa`;
}

export function getLogoutUrl(): string {
    return `${backendUrl}/auth/logout`;
}

export function getDwhUrl(): string {
    return `${backendUrlWithPathPrefix}/dwh-redirect`;
}
